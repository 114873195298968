<script setup>
const props = defineProps({
  useUrlQuery: Boolean,
  cars: Array,
  availableEquipment: Map,
})

const emits = defineEmits(['change']);

const {getDefaults, getSeats, getPower, getPromo, getProductionYears, getDriveTypes, getGearBoxes} = useParameters();
const {getFiltersDictionary, revTranslateFuel} = useDictionaries();
const route = useRoute();

const dropDowns = ref([]);
const showAll = ref(false);

const addToDropDowns = (el) => dropDowns.value.push(el);

const clearForm = () => {
  dropDowns.value.forEach(el => el.reset());
}

const bodies = ref(await useBackend().getBodies());
const productionYearOptions = ref(await getProductionYears());
const seats = ref(getSeats());
const power = ref(getPower());
const filters = getFiltersDictionary();

const drives = ref(await getDriveTypes());

const gearboxes = ref(await getGearBoxes());

const minPrice = ref(40000);
const maxPrice = ref(900000);

const search = ref({
  body: getDefaults('body', props.useUrlQuery),
  productionYear: getDefaults('productionYear', props.useUrlQuery),
  fuel: getDefaults('fuel', props.useUrlQuery),
  gearbox: getDefaults('gearbox', props.useUrlQuery),
  drive: getDefaults('drive', props.useUrlQuery),
  powerMin: getDefaults('powerMin', props.useUrlQuery),
  powerMax: getDefaults('powerMax', props.useUrlQuery),
  seats: getDefaults('seats', props.useUrlQuery),
  equip: [],
});


const preset = defineModel('preset', {default: () => ({})});

const setFilters = (filters) => {
  // search.value = Object.assign(filters, search.value);
}

const performSearch = (filter, value, isReset) => {
  if (!isReset) analytics(filter, value);
  if (filter === 'filter_moc_od' || filter === 'filter_moc_do') compare(value, filter === 'filter_moc_od' ? 'powerMax' : 'powerMin', false);

  const query = search.value;
  emits('change', query);
}

const analytics = (type, value) => {
  useAnalytics().analyticsLayer({
    'event': type,
    'filter_type': Object.values(value).join(",")
  });
}

const compare = (base, toCompare, less) => {
  if (search.value[toCompare][0] && base[0]) {
    base = parseInt(toRaw(base)[0]);
    const value = parseInt(search.value[toCompare][0]);

    if (less && base < value || !less && base > value) {
      search.value[toCompare] = [];
    }
  }
}

const preFilterParameters = (cars) => {
  const availableBodies = cars.map(i => i.bodyType.toLowerCase()).unique();
  const availableDriveTypes = cars.map(i => i.driveType).unique();
  const availableGearBoxes = cars.map(i => i.gearboxType).unique();
  const availableFuelTypes = cars.map(i => i.fuelType).unique();
  const availableSeats = cars.map(c => c.seats).unique();
  const availableProductionYears = cars.map(i => i.productionYear).unique();

  minPrice.value = cars.reduce((acc, val) => {
    if (acc === 0) return parseInt(val.price)
    if (parseInt(val.price) < acc) return parseInt(val.price);
    return acc;
  }, 0);

  maxPrice.value = cars.reduce((acc, val) => {
    if (parseInt(val.price) > acc) return parseInt(val.price);
    return acc;
  }, 0);

  bodies.value = {data: bodies.value.data.filter(b => availableBodies.indexOf(b.name.toLowerCase()) !== -1)};

  filters.drive = filters.drive.filter(i => availableDriveTypes.indexOf(i.name) !== -1);
  filters.gearbox = filters.gearbox.filter(g => availableGearBoxes.indexOf(g.name) !== -1);
  filters.fuel = filters.fuel.filter(f => availableFuelTypes.indexOf(f.name) !== -1);

  const filteredSeats = new Map

  seats.value.forEach((e, key) => {
    if (availableSeats.indexOf(key) !== -1) {
      filteredSeats.set(key, key);
    }
  });
  seats.value = filteredSeats;

  productionYearOptions.value = productionYearOptions.value.getFilteredMapByKeysArray(availableProductionYears);
  gearboxes.value = gearboxes.value.getFilteredMapByKeysArray(availableGearBoxes);
  drives.value = drives.value.getFilteredMapByKeysArray(availableDriveTypes);

}

onMounted(() => {
  console.log("🔎",search.value);
  preFilterParameters(props.cars)
})

defineExpose({
  clearForm,
  setFilters
})

</script>

<template>
  <div class="relative">
    <div class="grid grid-cols-4 md:grid-cols-8 xl:grid-cols-16 gap-2 w-full items-end"
         :class="{'grid-cols-5':!showAll}">
      <InputDropDown :ref="addToDropDowns"
                     @change="(v, isReset) => performSearch('filter_nadwozie',v,isReset)" v-model="search.body" label="Nadwozie" class="col-span-2" :options="bodies.data" :map="['name','name']" :preset="preset?.body" multiple />
                     <InputDropDown :ref="addToDropDowns"
                     @change="(v, isReset) => performSearch('filter_rok_produkcji',v,isReset)" v-model="search.productionYear" label="Rok produkcji" class="col-span-2" :options="productionYearOptions" :preset="preset?.productionYear" multiple />
                     <InputDropDown :ref="addToDropDowns"
                     @change="(v, isReset) => performSearch('filter_paliwo',v,isReset)" v-model="search.fuel" label="Paliwo" :options="filters.fuel" :map="['name','value']" class="col-span-2" :preset="preset?.fuel" multiple />
      <InputDropDown :class="['lg:block', {'hidden':!showAll}]" :ref="addToDropDowns"
                     @change="(v, isReset) => performSearch('filter_skrzynia',v,isReset)" v-model="search.gearbox" label="Skrzynia biegów" :options="gearboxes"  class="col-span-2" :preset="preset?.gearbox" multiple />
      <InputDropDown :class="['lg:block', {'hidden':!showAll}]" :ref="addToDropDowns"
                     @change="(v, isReset) => performSearch('filter_naped',v,isReset)" v-model="search.drive" label="Napęd" :options="drives"  class="col-span-2" :preset="preset?.drive" multiple />
                     <InputDropDown :class="['lg:block', {'hidden':!showAll}]" :ref="addToDropDowns"
                     @change="(v, isReset) => performSearch('filter_miejsca',v,isReset)" v-model="search.seats" label="Ilość miejsc" :options="seats" class="col-span-2" :preset="preset?.seats" multiple />
      <InputDropDown :class="['lg:block', {'hidden':!showAll}]" :ref="addToDropDowns"
                     @change="(v, isReset) => performSearch('filter_moc_od',v,isReset)" v-model="search.powerMin" label="Moc od" :options="power" class="col-span-2" :preset="preset?.powerMin" />
      <InputDropDown :class="['lg:block', {'hidden':!showAll}]" :ref="addToDropDowns"
                     @change="(v, isReset) => performSearch('filter_moc_do',v,isReset)" v-model="search.powerMax" label="Moc do" :options="power" class="col-span-2" :preset="preset?.powerMax" />
      <!--      <InputDropDown :ref="addToDropDowns" @change="performSearch" v-model="search.equip" label="Wyposażenie" :options="availableEquipment" class="col-span-2" multiple with-search />-->
      <ButtonToggle class="block lg:hidden text-azure text-sm" @toggle="(v) => showAll = v">
        <template v-slot:default>+6 filtrów</template>
        <template v-slot:toggled>zwiń</template>
      </ButtonToggle>


    </div>
  </div>
</template>
